import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from '../BackgroundImage';
import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { serializeImage } from '../../utils/SerializeImage';
import isEmpty from '../../utils/isEmpty';
import { isExternalUrl } from '../../utils/url';

import styles from './CardArticle.module.scss';

const CardArticle = ({
    image,
    title,
    text,
    href,
    type,
    index,
    date,
    modifier,
    containerModifier,
}) => {
    const { t } = useTranslation();

    const [isExternal, setIsExternal] = useState(false);
    const [isVisible, setIsVisible] = useState(index === 0);
    const hasImage = !!image?.url;

    useEffect(() => {
        setIsExternal(isExternalUrl(href));
    }, [href]);

    const serializedImage = serializeImage(image, true);
    let sizes = '(max-width: 750px) 100vw, (max-width: 1200px) 50vw, 384px';

    if (modifier === 'Tripple') {
        sizes = '(max-width: 750px) 100vw, (max-width: 1200px) 33vw, 384px';
    } else if (modifier === 'Double') {
        sizes = '(max-width: 750px) 100vw, (max-width: 1200px) 50vw, 640px';
    } else if (modifier === 'Single') {
        sizes = '(max-width: 750px) 100vw, 1200px';
    }

    const classes = classNames(styles['CardArticle'], {
        [styles[`CardArticle--${type}`]]: type,
        [styles[`CardArticle--${modifier}`]]: modifier,
        [styles[`CardArticle--${containerModifier}`]]: containerModifier,
        [styles[`CardArticle--NoImage`]]: !hasImage,
    });

    return (
        <VisibilitySensor onChange={(x) => setIsVisible(index === 0 || x)}>
            <li className={classes}>
                {hasImage && (
                    <div className={styles['CardArticle__Image']}>
                        <BackgroundImage
                            {...serializedImage}
                            quality={70}
                            sizes={sizes}
                        />
                    </div>
                )}
                {!isEmpty(date) && (
                    <div className={styles['CardArticle__Date']}>
                        <span className={styles['CardArticle__Day']}>
                            {date.day}
                        </span>
                        <span className={styles['CardArticle__Month']}>
                            {date.month}
                        </span>
                    </div>
                )}
                <div className={styles['CardArticle__Content']}>
                    <h3 className={styles['CardArticle__Title']}>{title}</h3>
                    <p className={styles['CardArticle__Text']}>{text}</p>
                </div>
                <a
                    href={href}
                    tabIndex={isVisible ? 0 : -1}
                    className={styles['CardArticle__Link']}
                    aria-label={t('cardArticle.readMoreLabel', { title })}
                    target={isExternal ? '_blank' : null}
                    rel={isExternal ? 'noopener noreferrer' : undefined}
                ></a>
            </li>
        </VisibilitySensor>
    );
};

CardArticle.propTypes = {
    image: PropTypes.object,
    title: PropTypes.string,
    href: PropTypes.string,
    type: PropTypes.string,
    index: PropTypes.number,
    date: PropTypes.object,
};

CardArticle.defaultProps = {
    image: {},
    title: '',
    text: '',
    href: '',
    type: '',
    date: {},
};

export default CardArticle;
