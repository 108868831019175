import React, { forwardRef, useRef, useImperativeHandle } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import BackgroundImage from '../../../BackgroundImage';

import styles from './ImageGalleryResponsiveFullscreenImage.module.scss';

const ImageGalleryResponsiveFullscreenImage = forwardRef(
    ({ image, children }, ref) => {
        const thumbRef = useRef(null);

        // add capability for parent components to scroll
        // this particular image into view
        useImperativeHandle(
            ref,
            () => ({
                imageId: image.id,
                scrollThumbIntoView: () => {
                    if (image.index === 1) {
                        return;
                    }

                    thumbRef.current.scrollIntoView({
                        inline: 'center',
                        behavior: 'smooth',
                    });
                },
            }),
            [image.id, image.index]
        );

        const containerClasses = classNames(
            styles['ImageGalleryResponsiveFullscreenImage__Container'],
            `ImageGalleryResponsiveFullscreenImage__Container--${image.index}`,
            {
                [styles[
                    'ImageGalleryResponsiveFullscreenImage__Container--Portrait'
                ]]: image.portrait,
            }
        );

        return (
            <div className={styles['ImageGalleryResponsiveFullscreenImage']}>
                <div className={containerClasses}>
                    <BackgroundImage {...image} />
                </div>
                <span
                    className={
                        styles[
                            'ImageGalleryResponsiveFullscreenImage__ScrollAnchor'
                        ]
                    }
                    ref={thumbRef}
                ></span>
                {children}
            </div>
        );
    }
);

ImageGalleryResponsiveFullscreenImage.propTypes = {
    image: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        index: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string,
        alt: PropTypes.string,
        portrait: PropTypes.bool.isRequired,
    }).isRequired,
    children: PropTypes.node,
};

export default ImageGalleryResponsiveFullscreenImage;
