import React, {
    useCallback,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTranslation } from 'next-i18next';
import useMediaQuery from '../../../../utils/useMediaQuery';
import debounce from '../../../../utils/debounce';

import ImageCaption from '../ImageCaption';
import { ImageDescription } from '../../../ImageGallery';
import BackgroundImage from '../../../BackgroundImage';

import { breakpoints } from '../../../../constants';

import styles from './ImageGalleryThumbnail.module.scss';

const ImageGalleryThumbnail = forwardRef(
    ({ image, active, onImageSelect, onFullscreenToggle }, ref) => {
        const thumbRef = useRef(null);

        // add capability for parent components to scroll
        // this particular image into view
        useImperativeHandle(
            ref,
            () => ({
                imageId: image.id,
                scrollThumbIntoView: debounce(
                    () => {
                        if (image.index === 1 || !thumbRef.current) {
                            return;
                        }

                        thumbRef.current.scrollIntoView({
                            inline: 'center',
                            behavior: 'smooth',
                        });
                    },
                    200,
                    false
                ),
            }),
            [image.id, image.index]
        );

        const isMobile = useMediaQuery({
            query: `(max-width: ${breakpoints.sm}px)`,
        });

        const isResponsive = useMediaQuery({
            query: `(min-width: ${breakpoints.s}px) and (max-width: ${breakpoints.l}px)`,
        });

        const isDesktop = useMediaQuery({
            query: `(min-width: ${breakpoints.l}px)`,
        });

        const handleImageSelect = useCallback(
            (e) => {
                onImageSelect(e, image);

                if (isResponsive) {
                    onFullscreenToggle();
                }
            },
            [image, isResponsive, onFullscreenToggle, onImageSelect]
        );

        const handleImageSelectKeyPress = useCallback(
            (e) => {
                e.preventDefault();

                if (e.key !== ' ' && e.key !== 'Enter') {
                    return;
                }

                handleImageSelect(e);
            },
            [handleImageSelect]
        );

        const { t } = useTranslation('common');

        const wrapperLargeAttrs = {
            tabIndex: isMobile ? -1 : 0,
            role: 'button',
            'aria-label': `${t('imagegallery.showimage')} ${image.index}`,
            onClick: handleImageSelect,
            onKeyPress: handleImageSelectKeyPress,
        };

        const thumbClasses = classNames(styles['ImageGalleryThumbnail'], {
            [styles['ImageGalleryThumbnail--Active']]: active,
        });

        const thumbContainerClasses = classNames(
            styles['ImageGalleryThumbnail__Container'],
            `ImageGalleryThumbnail__Container--${image.index}`,
            {
                [styles['ImageGalleryThumbnail__Container--Portrait']]:
                    image.portrait,
            }
        );

        const sizes = '(max-width: 1200px) 50vw, 128px';

        return (
            <div
                className={thumbClasses}
                {...(isResponsive || isDesktop ? wrapperLargeAttrs : [])}
                ref={thumbRef}
            >
                <div className={thumbContainerClasses}>
                    <div className={styles['ImageGalleryThumbnail__Image']}>
                        <BackgroundImage
                            {...image}
                            quality={40}
                            sizes={sizes}
                        />
                    </div>

                    {!isDesktop && (
                        <ImageCaption
                            id={`image-${image.id}`}
                            caption={
                                <ImageDescription
                                    title={image.title}
                                    credits={image.credits}
                                />
                            }
                        />
                    )}
                </div>
            </div>
        );
    }
);

ImageGalleryThumbnail.propTypes = {
    image: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        index: PropTypes.number.isRequired,
        title: PropTypes.string,
        credits: PropTypes.string,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string,
        alt: PropTypes.string,
        portrait: PropTypes.bool,
    }).isRequired,
    active: PropTypes.bool,
    onImageSelect: PropTypes.func.isRequired,
    onFullscreenToggle: PropTypes.func.isRequired,
};

ImageGalleryThumbnail.defaultProps = {
    active: false,
    onImageSelect: () => {},
    onFullscreenToggle: () => {},
};

export default ImageGalleryThumbnail;
