import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { serializeImage } from '../../utils/SerializeImage';
import BackgroundImage from '../BackgroundImage';

import styles from './ImageBlock.module.scss';

const ImageBlock = ({
    altText = '',
    file = '',
    credits = '',
    caption = '',
    focal = null,
    width = null,
    height = null,
    showCredits = true,
    showCaption = false,
    showDownload = true,
    shouldLazyLoad = true,
    download = '',
    creditsRight = false,
    modifiers = [],
    sizes = '',
}) => {
    const { t } = useTranslation();

    if (!file) {
        return null;
    }

    const classes = classNames(
        styles['ImageBlock'],
        { [styles['ImageBlock--Loaded']]: true },
        modifiers.map((modifier) => styles[`ImageBlock--${modifier}`])
    );

    const serializedImage = serializeImage(
        {
            altText: altText,
            src: file,
            credits: credits,
            caption: caption,
            focal: focal,
            width: width,
            height: height,
        },
        true
    );

    return (
        <div className={classes}>
            <BackgroundImage
                {...serializedImage}
                shouldLazyLoad={shouldLazyLoad}
                sizes={sizes}
            />

            {(showCredits || showCaption) && (
                <figcaption
                    className={classNames(styles['ImageBlock__Credits'], {
                        [styles['ImageBlock__Credits--Right']]: creditsRight,
                    })}
                >
                    {caption && <p>{caption}</p>}
                    {credits && <p>{`${t('image.photo')} : ${credits}`}</p>}
                </figcaption>
            )}

            {showDownload && download && (
                <a
                    className={styles['ImageBlock__Download']}
                    href={download}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="sr-only">{t('image.download')}</span>
                </a>
            )}
        </div>
    );
};

ImageBlock.propTypes = {
    altText: PropTypes.string,
    file: PropTypes.string,
    focal: PropTypes.object,
    credits: PropTypes.string,
    caption: PropTypes.string,
    showCredits: PropTypes.bool,
    showCaption: PropTypes.bool,
    showDownload: PropTypes.bool,
};

export default ImageBlock;
